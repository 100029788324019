.Toastify__toast.Toastify__toast--default {
  display: flex !important;
  justify-content: center !important;
}
.Toastify__toast-body {
  margin: 0;
}
.Toastify__toast-container--bottom-center {
  left: 43% !important;
}

.Toastify__toast {
  width: 591px !important;
  height: 117px !important;
  padding: 0 !important;
}
.Toastify__close-button {
  padding: 5px;
}
.custom-toast {
  h6 {
    font-weight: bold;
    color: #1c1b21;
  }
  p {
    color: #545357;
  }
  .error {
    background: rgba(227, 75, 75, 0.3);
    i {
      color: #e34b4b;
    }
  }
  .warning {
    background: rgba(255, 219, 131, 0.3);
    i {
      color: #484233;
    }
  }
  .success {
    background: rgba(45, 160, 80, 0.3);
    i {
      color: #2da050;
    }
  }
  .info {
    background: #9bd2ff;
    i {
      color: #345875;
    }
  }
}

.custom-toast .success {
  padding: 50px 22px !important;
}

.custom-toast .warning {
  padding: 50px 22px !important;
}

.custom-toast .error {
  padding: 50px 22px !important;
}

.custom-toast .info {
  padding: 50px 22px !important;
}
