.table {
  font-size: 14px;
}
a,
button {
  &.bg-light {
    &:hover,
    &:focus {
      background-color: #fff !important;
      border-color: #e36c4b !important;
    }
  }
}

.btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled)
  .svg-icon
  svg
  g
  [fill],
.btn.btn-light-primary:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-primary.focus:not(.btn-text) .svg-icon svg g [fill] {
  fill: #e36c4b;
}
.btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled) i,
.btn.btn-light-primary:focus:not(.btn-text) i,
.btn.btn-light-primary.focus:not(.btn-text) i {
  color: #e36c4b;
}

.btn.btn-outline-secondary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-outline-secondary:focus:not(.btn-text),
.btn.btn-outline-secondary.focus:not(.btn-text) {
  background-color: transparent;
}
.input-group {
  &.input-group-solid {
    background-color: #e4e4e4;
    .form-control {
      color: #8c8c8e;
      height: auto;
    }
  }
  i {
    display: flex;
    align-items: center;
    &:after,
    &:before {
      color: #8e8e8e;
    }
  }
}
.nav {
  &.nav-tabs {
    .show > .nav-link,
    .nav-link:hover:not(.disabled),
    .nav-link.active {
      color: #51429c;
    }
  }
  &.nav-bold {
    .nav-item {
      .nav-link {
        font-weight: 600;
        font-size: 16px;
        &.active {
          color: #1c1b21;
        }
      }
    }
  }
}

.border-dashed {
  border-style: dashed;
}
.bg-gray {
  background: #e8e8e8;
}
.global-search {
  width: 44%;
}
.font-12 {
  font-size: 12px;
}
.btn-custom {
  color: #e36c4b;
  border: 1px solid #e36c4b;
  background-color: #f8f9fa;
  &:hover {
    color: #e36c4b;
    background-color: #f8f9fa;
  }
}
.modal {
  .modal-header {
    &.file-upload {
      .close {
        span {
          display: block;
        }
      }
    }
  }
  .modal-body {
    .table {
      thead {
        th {
          position: sticky;
          top: 0;
        }
      }
    }
  }
}
.fa-angle-left {
  font-size: 2.2rem;
}
.font-weight-medium {
  font-weight: 500;
}
.text-grey {
  color: $gray-800;
}
.card-header {
  border-bottom: none;
}
#kt_subheader_search_form {
  color: #1c1b21;
  font-weight: 500;
}

.input-group.input-group-solid:focus-within {
  background-color: #fff !important;
  border: 1px solid #51429c !important;
  path {
    fill: #51429c !important;
  }
}

.input-group.input-group-solid {
  border: 1px solid transparent;
  path {
    fill: #8c8c8e;
  }
}

.btn.btn-hover-primary:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-hover-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-primary.dropdown-toggle,
.show .btn.btn-hover-primary.btn-dropdown {
  background-color: #545357 !important;
  border-color: #545357 !important;
}
.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-hover-primary:focus:not(.btn-text),
.btn.btn-hover-primary.focus:not(.btn-text) {
  background-color: #545357 !important;
  border-color: #545357 !important;
}
.aside-menu .menu-nav {
  > .menu-item {
    &.menu-item-active {
      .menu-heading,
      .menu-link {
        span {
          font-weight: 600 !important;
        }
      }
    }
  }
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  padding: 0.546rem;
}
#kt_subheader_search_form {
  padding: 0.546rem;
}

.react-bootstrap-table th.sortable .svg-icon-sort {
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  .global-search {
    width: 100%;
  }
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #51429c !important;
}
.form-control {
  &:hover {
    border-color: #51429c !important;
  }
}
.form-control:focus-within {
  border-color: #51429c !important;
}
.css-6q0nyr-Svg {
  color: #e36c4b;
}

.MuiBreadcrumbs-ol {
  font-family: inter;
}

.MuiTypography-colorTextSecondary {
  font-family: inter !important;
}

.new {
  fill: #1c1b21;
  font-family: inter;
}

.uploader {
  border-color: #c0c0c0;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
}
.align-self-end {
  display: block;
}

.box-div {
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#Oval-Copy-3 {
  fill: #f4f4f4;
}

// client-device-configure
.font {
  margin-right: 4px;
  color: #8c8c8e;
  font-size: 14px;
  font-weight: 600;
}

#plus {
  width: 9px !important;
  height: 12px !important;
}

.custom-table-sticky-modal {
  &.react-bootstrap-table tbody {
    display: block;
    max-height: 385px;
    // overflow-y: scroll;
    overflow: auto;
  }
  &.react-bootstrap-table thead > tr,
  &.react-bootstrap-table tbody > tr {
    display: table;
    width: auto;
    table-layout: fixed;
  }
}

.connected {
  height: 7px;
  width: 7px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

.disconnected {
  height: 7px;
  width: 7px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

.newconnect {
  height: 7px;
  width: 7px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 3px;
  margin-left: 6px;
}
.newdisconnect {
  height: 7px;
  width: 7px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 3px;
  margin-left: 6px;
}
.custom-invert {
  color: #e36c4b !important;
  border: 1px solid #e36c4b !important;
  border-radius: 4px !important;
  background-color: transparent !important;
}
.custom-invert:hover {
  color: #fff !important;
  background-color: #e36c4b !important;
  & .spacedevice-trash {
    color: #fff;
  }
}
.custom-border {
  border: 2px dashed #51429c;
  border-radius: 17px;
  background-color: #f8f8f8;
}
.custom-padding {
  padding: 3rem 5rem;
}

.uploading-text {
  color: #1c1b21;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 29px;
  font-family: Inter;
}
.header-h1-custom {
  color: #1c1b21;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.18px;
  line-height: 20px;
}
.uploading-desc {
  color: #545357;
  font-family: Inter;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}
.custom-header {
  color: #202228;
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 29px;
}
.custom-tb-subheader {
  color: #202228;
  font-family: Inter;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
}
.custom-tb-nameheader {
  color: #202228;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}
.custom-tb-namepara {
  color: rgba(32, 34, 40, 0.7);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  width: 17rem !important;
}
.space-card {
  border: 1px solid #e4e4e4;
  border-radius: 13px;
  background-color: #ffffff;
}
.space-card-header {
  color: #1c1b21;
  font-family: Inter;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
}
.space-sub-header-card {
  color: #8c8c8e;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.space-sub-header-card-dark {
  color: #545357;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
}
.space-header-text-card {
  color: #e36c4b;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}
.space-header-text-card-dark {
  color: #8c8c8e;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.child-space-card {
  width: 115px;
  border: 1px solid #dedede;
  border-radius: 13px;
  background-color: #f1f1f1;
  min-height: 7rem;
}
.child-space-card-header {
  color: #1c1b21;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}
.child-space-card-text {
  color: #51429c;
  font-family: Inter;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}
.child-space-card-Subtext {
  color: #545357;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 12px;
}
.gateway-card-spcaes {
  border: 2px solid rgb(155, 210, 255);
  border-radius: 13px;
  min-height: 7rem;
}
.slave-card-spaces {
  border: 2px solid rgb(255, 219, 131);
  border-radius: 13px;
  min-height: 7rem;
}
.smart-card-spaces {
  border: 2px solid rgb(144, 238, 144);
  border-radius: 13px;
  min-height: 7rem;
}
.device-name-spaces,
.device-name-spaces-sm {
  color: #1c1b21;
  font-family: Inter;
  letter-spacing: 0;
  line-height: 16px;
}
.device-name-spaces {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3rem;
}
.device-name-spaces-sm {
  font-size: 10px;
  font-weight: 500;
}
.device-status-spaces {
  color: #1c1b21;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.spaces-active-status {
  display: inline-block;
  margin: 0 7px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #3cc74f;
}
.spaces-inactive-status {
  display: inline-block;
  margin: 0 7px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #ff0000;
}
.show-pointer:hover {
  cursor: pointer;
}
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation0.MuiPaper-rounded {
  border: 1px solid #ededed !important;
  background-color: #ffffff !important;
  box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.09) !important;
}
.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button:not(:last-child) {
  border-bottom: 1px solid #e3e3e3 !important;
}

.location-search-input {
  position: relative !important;
}

.autocomplete-dropdown-container {
  position: absolute !important;
  z-index: 100 !important;
  top: 40px !important;
  left: 0 !important;
  right: 0 !important;
  display: inline-block !important;
}
.autocomplete-dropdown-container-border {
  border-radius: 5px !important;
  border: 1px solid #e0e0e0 !important;
  overflow: hidden;
}
.suggestion-item {
  background: #fff !important;
  padding: 0.7rem !important;
}
.suggestion-item--active {
  padding: 0.7rem !important;
  background: #eee !important;
}
.mt-negative-icon {
  margin-top: -2px !important;
}
.custom-date-element {
  border-radius: 4px;
  background-color: #f2f2f2;
  height: 24px;
}
.circular-btn {
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-edit-icon {
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#newname {
  width: 30px;
  height: 24px;
}

// .form-control {
//   padding: 4px !important;
// }
#NewStyle {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
#SaveStyle {
  margin-left: 10px !important;
}

.react-switch-bg {
  border: 1px inherit !important;
}

.react-switch-handle {
  border: 1px solid transparent !important;
}
#customNew {
  margin-right: -8px;
}

.aws-delete-icon {
  & svg {
    width: 20px !important;
    height: 20px !important;
  }
}

.table-sticky-scrollable-1 {
  overflow: auto !important;
  max-height: 60vh !important;
  & th {
    position: sticky !important;
    top: 0 !important;
    background-color: #fff !important;
  }
}

.sticky-table-header-z-index {
  & th {
    z-index: 1000 !important;
  }
}

.table-sticky-scrollable {
  overflow-y: auto !important;
  height: 70vh;
  overflow-x: auto !important;
  th {
    position: sticky !important;
    top: 0 !important;
    background: #fff !important;
  }
  table {
    border-collapse: collapse !important;
    width: 100% !important;
  }
  td,
  th {
    padding: 10px !important;
    text-align: center !important;
  }
}

.meter {
  position: relative;
  background: transparent;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  padding: 10px;
}
.meter > span {
  display: block;
  height: 1rem;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  position: relative;
  overflow: hidden;
}
.blue-bg-animate > span {
  background-color: #51429c;
}
.meter > span:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  background-size: 25px 25px;
  animation: move 2s linear infinite;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: visible;
}
@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
.active-mask-input {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.active-mask-input:focus-within {
  border: 1px solid #51429c !important;
  font-weight: 400 !important;
}
.active-mask-input:focus-within .hover-class:hover {
  background-color: #fff !important;
}
.spacedevice-trash {
  font-size: 16px;
  color: #e36c4b;
}

.spacedevice-remove-icon {
  position: absolute !important;
  top: -5px;
  right: -5px;
  height: 26px !important;
  width: 26px !important;
  border-radius: 50% !important;
}

.global-search-dropdown {
  position: absolute;
  top: 45px;
  left: 0;
  min-width: 50vw;
  min-height: 20vh;
  z-index: 1;
  @media (min-width: 992px) and (max-width: 1280px) {
    min-width: 65vw;
  }

  @media (max-width: 991px) {
    min-width: 90vw;
  }
}
.dropdown-tab-btn {
  border-radius: 4px;
  background-color: #f2f2f2;
  color: #51429c;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}
.dropdown-active-tab {
  border-radius: 4px;
  background-color: #545357 !important;
  color: #ffffff !important;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}
.list-hover-effect {
  padding: 1rem;
  &:hover {
    cursor: pointer;
    background-color: #f4f4f4;
  }
  &:focus {
    background-color: #f4f4f4;
    outline: none;
    border: none;
  }
}
.list-active-effect {
  background-color: #f4f4f4;
  border: none !important;
  outline: none !important;
  &:focus,
  &:active {
    border: none !important;
    outline: none !important;
  }
}

.loading-indicator-globalsearch {
  color: #51429c;
  display: flex;
  padding: 8px;
  transition: color 150ms ease 0s;
  align-self: center;
  font-size: 4px;
  line-height: 1;
  margin-right: 4px;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
}
.loading-dot-1 {
  animation: 1s ease-in-out 0ms infinite normal none running animation-loader-1;
  background-color: currentcolor;
  border-radius: 1em;
  display: inline-block;
  height: 1em;
  vertical-align: top;
  width: 1em;
}
.loading-dot-2 {
  animation: 1s ease-in-out 160ms infinite normal none running animation-loader-2;
  background-color: currentcolor;
  border-radius: 1em;
  display: inline-block;
  margin-left: 1em;
  height: 1em;
  vertical-align: top;
  width: 1em;
}
.loading-dot-3 {
  animation: 1s ease-in-out 320ms infinite normal none running animation-loader-3;
  background-color: currentcolor;
  border-radius: 1em;
  display: inline-block;
  margin-left: 1em;
  height: 1em;
  vertical-align: top;
  width: 1em;
}
@keyframes animation-loader-1 {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
@keyframes animation-loader-2 {
  0%,
  80%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}
@keyframes animation-loader-3 {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
.active-status-btn {
  .MuiToggleButton-root-108.Mui-selected {
    background-color: #2da050 !important;
    color: #fff !important;
  }
}
.inactive-status-btn {
  .MuiToggleButton-root-108.Mui-selected {
    background-color: #e34b4b !important;
    color: #fff !important;
  }
}
.MuiInputLabel-outlined {
  z-index: 0 !important;
}
.date-range-picker-params {
  position: absolute;
  top: 0;
  background-color: transparent !important;
}

.dropdown-toggle.noCaret:after {
  display: none;
  background-color: silver;
}
.calender-btn {
  background-color: #e5e5e5 !important;
  border-color: #e5e5e5;
}
.input-group-append {
  z-index: 0 !important;
}

.rdrInputRanges {
  display: none;
}

.disableCalender {
  .rdrCalendarWrapper {
    filter: opacity(0.7) !important;
    pointer-events: none !important;
  }
}

@media screen and (max-width: 600px) {
  .rdrDateRangePickerWrapper {
    display: flex;
    flex-direction: column;
    max-width: 90vw;
    overflow: hidden;
    .rdrDefinedRangesWrapper {
      width: 100%;
      .rdrStaticRanges {
        flex-direction: row;
        overflow-x: auto;
        .rdrStaticRange {
          flex: 1 0 40%;
          margin: 10px 5px;
          .rdrStaticRangeLabel {
            border: 1px solid #b6bdc0;
            border-radius: 5px;
            padding: 5px 10px;
            text-align: center;
          }
        }
      }
    }
  }
}
.text-green-custom {
  color: #2da050;
}
.active-dashboard-conn {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: green;
  animation: blinker 1s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.legal {
  margin-top: 70px;
  margin-bottom: 70px;
  &-header {
    font-size: 40px;
    font-weight: 300;
    color: #444;
    text-transform: uppercase;
    position: relative;
    display: block;
    padding-bottom: 0px;
    line-height: 1.5;
    font-family: 'Montserrat', Arial, sans-serif;
  }
  & p::selection,
  & h1::selection {
    color: #818892 !important;
    background: #f9f6f0 !important;
  }
  & p {
    color: rgb(119, 119, 119);
    font-size: 17px;
    font-weight: 400;
    line-height: 31px;
    font-family: 'Work Sans', Arial, sans-serif;
    margin-bottom: 3rem;
  }
  & .email-link {
    margin-bottom: 3rem;
    color: rgb(0, 51, 153);
    font-weight: 400;
    line-height: 31px;
    font-size: 17px;
    &:hover,
    &:active {
      color: rgb(0, 51, 153);
      text-decoration: underline;
    }
  }
  & .legal-footer {
    margin-bottom: 70px;
  }
}
.company-logo-legal {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 3rem;
}
.w-custom-30 {
  width: 40%;
}

.w-custom-60 {
  width: 60%;
}

.h-max-8 {
  max-height: 8rem;
}
