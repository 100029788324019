// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

// Default Layout themes
@import './_metronic/_assets/sass/themes/layout/header/base/light.scss';
@import './_metronic/_assets/sass/themes/layout/header/menu/light.scss';
// @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// @import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
@import './_metronic/_assets/sass/themes/layout/header/base/light.scss';
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
@import './_metronic/_assets/sass/themes/layout/header/menu/light.scss';
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
// .brand-dark {
//   @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// }
// Light
.brand-light {
  @import './_metronic/_assets/sass/themes/layout/brand/light.scss';
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
@import './_metronic/_assets/sass/themes/layout/aside/light.scss';

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap");

body {
  font-family: 'Inter', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.numberCircle {
  border-radius: 100%;
  padding: 4px;
  background: #fff;
  border: 2px solid #51429c !important;
  color: #e36c4b;
  text-align: center;
  font: 14px Arial, sans-serif;
}

.wellIcon {
  background: #e36c4b !important;
  color: white !important;
  margin-top: -25px !important;
  margin-left: -10px !important;
  width: 14px !important;
  height: 14px !important;
  border-radius: 100% !important;
  text-align: center !important;
  font-size: 9px;
  font-weight: 700;
  padding-left: 1px;
}
.badge-qty {
  min-height: 27px;
  min-width: 28px;
  border-radius: 76%;
  font-size: 12px;
  /* font-family: Inter; */
  /* font-weight: bold; */
  background-color: white;
}
.text-device {
  height: 25px;
  width: 165px;
  color: #ffffff;
  font-family: Inter;
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
}
@import './custom-styles.scss';
