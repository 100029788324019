.subdrawer {
  // margin-left: 265px !important;
  width: 265px !important;
}
.styleHrRuler {
  display: block;
  margin-top: -1.8rem;
  font-size: 12px !important;
  width: 40%;
  padding-left: 1rem;
  z-index: 100;
  background-color: #ebebeb;
  letter-spacing: 1px;
}

.sub-drawer-active {
  width: 70px !important;
}

.hover-bg:hover {
  background-color: #d5d5d5;
}
.active-link-aside {
  background-color: #d5d5d5;
  font-weight: 600 !important;
}
.subdrawer a {
  color: #1c1b21 !important;
  // font-weight: 500 !important;
  font-size: 14px !important;
}

body {
  &.aside-fixed.aside-minimize.has-subdrawer .wrapper {
    margin-left: 227px !important;
  }
  &.aside-fixed.aside-minimize.has-subdrawer .subheader,
  &.aside-fixed.aside-minimize.has-subdrawer .header {
    // margin-left: 265px !important;
  }
  &.aside-minimize {
    .subdrawer {
      // margin-left: 70px !important;
    }
  }
}
@media only screen and (max-width:991px){
  body {
    &.aside-fixed.aside-minimize.has-subdrawer .wrapper {
      margin-left: 0 !important;
    }
    &.aside-minimize {
      .subdrawer {
        margin-left: 0 !important;
      }
    }
  }
}