//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Override primary color variants
$primary:       									#E36C4B; // Bootstrap variable
$primary-hover:    									#C44E2D; // Custom variable
$primary-light:    									#fce8d7; // Custom variable
$primary-inverse:  									#FFFFFF; // Custom variable

// Override secondary color variants
$secondary:     									#F2F2F2 !default; // Bootstrap variable
$secondary-hover:  									#E5E5E5 !default; // Custom variable
$secondary-light:  									#E1F0FF !default; // Custom variable
$secondary-inverse:								    #51429C !default; // Custom variable

